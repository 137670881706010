body {
  background-color: black;
  margin: 0;
  font-family: "Raleway", sans-serif;
}

h1 {
  font-family: "Bebas Neue", sans-serif;
  font-size: 5em;
}

::-webkit-scrollbar
{
  width: 12px;
}
::-webkit-scrollbar-thumb
{
  background: #FF6F4D;
  border-radius: 6px;
}
